<template>
  <div class="animated fadeIn leave-list">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>لیست مرخصی‌ها</h3>
          </v-col>
          <v-col>
            <v-btn
              class="primary-btn"
              style="float: left"
              @click="
                addLeaveItemDialog = true;
                getEmployeesList();leaveDetails ={}
              "
            >
              <v-icon left>add</v-icon>
              <h6 class="mt-1">ثبت مرخصی جدید</h6>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-row class="time-row">
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateFrom">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateFrom"
                    label=" تاریخ از "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateFrom"
                  element="dateFrom"
                  color="#00a7b7"
                /> </v-col
              ><v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <span id="dateTo">
                  <v-text-field
                    outlined
                    dense
                    type="text"
                    append-icon="calendar_today"
                    v-model="dateTo"
                    label=" تاریخ تا "
                    :editable="true"
                    class="date-input"
                  >
                  </v-text-field>
                </span>

                <date-picker
                  v-model="dateTo"
                  element="dateTo"
                  color="#00a7b7"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  class="submit-btn primary-btn"
                  style="float: right"
                  @click="getLeaveList()"
                  :disabled="Busy"
                  >اعمال</v-btn
                >
              </v-col>
            </v-row>
            <hr />

            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="در بازه زمانی انتخاب شده مرخصی ثبت نشده است"
              empty-filtered-text="در بازه زمانی انتخاب شده مرخصی ثبت نشده است"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              @row-clicked="onRowSelected"
              tbody-tr-class="hover-tr"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>

              <template v-slot:cell(description)="data">
                <v-tooltip right max-width="600" v-if="deviceType != 'mobile'">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        data.value.length > 20
                          ? data.value.slice(0, 20) + "..."
                          : data.value
                      }}
                    </span>
                  </template>
                  <p class="text-right p-2 text-justify">{{ data.value }}</p>
                </v-tooltip>
                <p v-else>
                  {{
                    data.value.length > 20
                      ? data.value.slice(0, 20) + "..."
                      : data.value
                  }}
                </p>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="addLeaveItemDialog" width="600">
      <v-card class="pa-3" style="overflow-x:hidden">
        <v-card-title class="mb-2">
          <h4>ثبت مرخصی جدید</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addLeaveItemDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="leaveDetails.userId"
            :items="employees"
            item-text="name"
            item-value="id"
            label="کارمند"
            v-if="!employeeBusy"
            outlined
            dense
          ></v-select>
          <v-select
            v-model="leaveDetails.type"
            :items="types"
            item-text="text"
            item-value="value"
            label="نوع مرخصی"
            outlined
            dense
          ></v-select>
          <v-row class="time-row">
            <v-col cols="12">
              <span id="newDateFrom">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="leaveDetails.newDateFrom"
                  label=" تاریخ از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="leaveDetails.newDateFrom"
                element="newDateFrom"
                color="#00a7b7"
              /> </v-col
            ><v-col cols="12">
              <span id="newDateTo">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="leaveDetails.newDateTo"
                  label=" تاریخ تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="leaveDetails.newDateTo"
                element="newDateTo"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row class="time-row" v-if="leaveDetails.type == 'hourly'">
            <v-col cols="12">
              <span id="newTimeFrom">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="leaveDetails.newTimeFrom"
                  label=" ساعت از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="leaveDetails.newTimeFrom"
                element="newTimeFrom"
                color="#00a7b7"
                type="time"
              />
            </v-col>
            <v-col cols="12">
              <span id="newTimeTo">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="leaveDetails.newTimeTo"
                  label=" ساعت تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="leaveDetails.newTimeTo"
                element="newTimeTo"
                color="#00a7b7"
                type="time"
              />
            </v-col>
          </v-row>
          <v-select
            v-model="leaveDetails.state"
            :items="states"
            item-text="text"
            item-value="value"
            label="حالت مرخصی"
            outlined
            dense
            class="mt-5"
          ></v-select>

          <v-select
            v-model="leaveDetails.status"
            :items="status"
            item-text="text"
            item-value="value"
            label="وضعیت مرخصی"
            outlined
            dense
          ></v-select>

          <v-file-input
            @change="myUpload(file)"
            v-model="file"
            outlined
            dense
            label="بارگذاری فایل"
            prepend-inner-icon="upload_file"
            prepend-icon=""
            class="file-input"
          ></v-file-input>
          <v-progress-linear
            rounded
            v-if="myFile.showProgress"
            height="10"
            :value="myFile.uploadPercentage"
            class="mb-4 mt-0"
          >
          </v-progress-linear>
          <v-row>
            <div
              v-if="myFile.isUploaded"
              color="green"
              class="mt-3 mb-1 pa-3"
              style="font-size: 16px"
            >
              فایل با موفقیت بارگذاری شد.
            </div>
          </v-row>

          <v-textarea
            v-model="leaveDetails.description"
            label="توضیحات"
            outlined
            dense
            class="mt-5"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="primary-btn"
            outlined
            @click="addToLeaveList()"
            :disabled="
              !leaveDetails.description ||
              !leaveDetails.type ||
              !leaveDetails.status ||
              !leaveDetails.state ||
              !leaveDetails.newDateTo ||
              !leaveDetails.newDateFrom || computedHourDisabled
            "
          >
            ثبت
          </v-btn>
          <v-btn
            class="red-btn"
            outlined
            @click="
              addLeaveItemDialog = false;
              file = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
              
            "
          >
            لغو
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="leaveItemDialog" width="600">
      <v-card class="pa-3" v-if="!edited">
        <v-card-title class="mb-2">
          <h4>جزییات مرخصی</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2 close-icon"
            @click="
              leaveItemDialog = false;
              edited = false;
            "
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3">
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">نام کارمند:</span>
              <span class="h2"> {{ leaveInfo.userName }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">نوع مرخصی :</span>
              <span class="h2"> {{ leaveInfo.type }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">حالت مرخصی:</span>
              <span class="h2"> {{ leaveInfo.state }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">وضعیت مرخصی :</span>
              <span class="h2"> {{ leaveInfo.status }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">تاریخ شروع :</span>
              <span class="h2"> {{ leaveInfo.startDate }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">تاریخ پایان :</span>
              <span class="h2"> {{ leaveInfo.endDate }}</span>
            </v-col>
          </v-row>
          <v-row v-if="leaveInfo.type == 'ساعتی'">
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">زمان شروع :</span>
              <span class="h2"> {{ leaveInfo.startTime }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="h2 primary--text">زمان پایان :</span>
              <span class="h2"> {{ leaveInfo.endTime }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col >
              <span class="h2 primary--text">ثبت شده توسط:</span>
              <span class="h2"> {{ leaveInfo.registerentName }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text"> فایل مربوطه:</span>
              <a
                v-if="leaveInfo.fileId"
                class="h2 px-2"
                :href="leaveInfo.fileUrl"
                target="_blank"
                style="color: #0099cc !important"
              >
                دانلود
              </a>

              <span class="h2 px-2" v-else>فایلی وجود ندارد.</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="h2 primary--text">توضیحات :</span>
              <p class="h2 mt-1 text-justify" style="line-height: 24px">
                {{ leaveInfo.description }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn @click="edited = true" class="secondary-btn mb-4 me-5 pa-5">
          <v-icon left>edit</v-icon>
          ویرایش</v-btn
        >
      </v-card>
      <v-card v-else style="overflow-x:hidden">
        <v-card-title class="mb-2">
          <h4>ویرایش مرخصی</h4>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-3 time-row">
            <v-col>
              <div class="disabled-fields grey lighten-3">
                <span class="h3"> نام کارمند: {{ selectedEdit.userName }}</span>
              </div>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedEdit.type"
                :items="types"
                label="نوع مرخصی"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-select
                v-model="selectedEdit.state"
                :items="states"
                label="حالت مرخصی"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="selectedEdit.status"
                :items="status"
                label="وضعیت مرخصی"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <span id="editDateFrom">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.startDate"
                  label=" تاریخ از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.startDate"
                element="editDateFrom"
                color="#00a7b7"
              /> </v-col
            ><v-col>
              <span id="editDateTo">
                <v-text-field
                  outlined
                  dense
                  type="text"
                  append-icon="calendar_today"
                  v-model="selectedEdit.endDate"
                  label=" تاریخ تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.endDate"
                element="editDateTo"
                color="#00a7b7"
              />
            </v-col>
          </v-row>
          <v-row v-if="selectedEdit.type == 'hourly'" class="time-row">
            <v-col>
              <span id="editTimeFrom">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="selectedEdit.startTime"
                  label=" ساعت از "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.startTime"
                element="editTimeFrom"
                color="#00a7b7"
                type="time"
              />
            </v-col>
            <v-col>
              <span id="editTimeTo">
                <v-text-field
                  outlined
                  dense
                  append-icon="schedule"
                  v-model="selectedEdit.endTime"
                  label=" ساعت تا "
                  :editable="true"
                  class="date-input"
                >
                </v-text-field>
              </span>

              <date-picker
                v-model="selectedEdit.endTime"
                element="editTimeTo"
                color="#00a7b7"
                type="time"
              />
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col
              ><div class="disabled-fields grey lighten-3">
                <span class="h3">
                  ثبت شده توسط: {{ selectedEdit.registerentName }}</span
                >
              </div>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-file-input
                @change="editedUpload(file)"
                v-model="file"
                outlined
                dense
                label=" بارگذاری فایل"
                prepend-inner-icon="upload_file"
                prepend-icon=""
                class="file-input mb-6"
              ></v-file-input>
              <v-progress-linear
                rounded
                v-if="myFile.showProgress"
                height="10"
                :value="myFile.uploadPercentage"
                class="mb-4 mt-0"
              >
              </v-progress-linear>
              <v-row>
                <div
                  v-if="myFile.isUploaded"
                  color="green"
                  class="mt-3 mb-1 pa-3"
                  style="font-size: 16px"
                >
                  فایل با موفقیت بارگذاری شد.
                </div>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="time-row">
            <v-col>
              <v-textarea
                v-model="selectedEdit.description"
                label="توضیحات"
                outlined
                dense
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn @click="editLeaveItem()" class="primary-btn mt-4 mx-2"
            >ثبت</v-btn
          >
          <v-btn
            @click="
              edited = false;
              file = [];
              myFile.isUploaded = false;
              myFile.showProgress = false;
            
            "
             :disabled="computedEditHourDisabled"
            class="red-btn mt-4"
            >لغو</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      deviceType: "",
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "userName", label: "نام کارمند" },
        { key: "userRole", label: "سمت" },
        { key: "type", label: "نوع مرخصی" },
        { key: "startDate", label: "تاریخ شروع" },
        { key: "endDate", label: "تاریخ پایان" },
        { key: "startTime", label: "ساعت شروع" },
        { key: "endTime", label: "ساعت پایان" },
        { key: "state", label: "حالت" },
        { key: "status", label: "وضعیت" },
        { key: "description", label: "توضیحات" },
      ],
      Items: [],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      role: "",
      addLeaveItemDialog: false,
      types: [
        { value: "hourly", text: "ساعتی" },
        { value: "daily", text: "روزانه" },
      ],
      states: [
        { value: "eligible", text: "استحقاقی" },
        { value: "incentive", text: "تشویقی" },
        { value: "withoutPay", text: "بدون حقوق" },
        { value: "sick", text: "استعلاجی" },
      ],
      status: [
        { value: "registered", text: "ثبت شده" },
        { value: "approved", text: "تایید شده" },
        { value: "rejected", text: "رد شده" },
      ],
      showProgress: false,
      uploadButDisable: false,
      variant: "info",
      striped: true,
      max: 100,
      uploadPercentage: 0,
      isUploaded: false,
      file: [],
      reportFiles: [],
      request: {},
      myFile: new Object({
        uploadPercentage: 0,
      }),
      fileBusy: false,
      fileFilter: "",
      leaveDetails: [],
      employees: [],
      employeeBusy: false,
      selected: {},
      edited: false,
      leaveItemDialog: false,
      editedDateFrom: "",
      editedDateTo: "",
      editedTimeFrom: "",
      editedTimeTo: "",
      selectedEdit: {},
      leaveInfo: {},
    };
  },
computed:{
   computedEditHourDisabled() {
      if(this.selectedEdit.type == 'hourly'){
        if(this.selectedEdit.startTime && this.selectedEdit.endTime){
          return false;
        }else{
          return true;
        }
      }
    },
    computedHourDisabled() {
      if(this.leaveDetails.type == 'hourly'){
        if(this.leaveDetails.newTimeFrom && this.leaveDetails.newTimeTo){
          return false;
        }else{
          return true;
        }
      }
    },


},
  methods: {
    getEmployeesList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/employees/list",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.employeeBusy = false;
          if (res.status == 200) {
            this.leaveDetails.userId = "";
            this.employees = res.data;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.Busy = false;
        });
    },
    getLeaveList() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leaves/getAll/",
          {
            startDate: this.dateFrom + "00:00",
            endDate: this.dateTo + "23:59",
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          this.vLoading = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.Busy = false;
        });
    },
    addToLeaveList() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leave/new",
          {
            userId: this.leaveDetails.userId,
            startDate:
              this.leaveDetails.type == "hourly"
                ? this.leaveDetails.newDateFrom + this.leaveDetails.newTimeFrom
                : this.leaveDetails.newDateFrom,

            endDate:
              this.leaveDetails.type == "hourly"
                ? this.leaveDetails.newDateTo + this.leaveDetails.newTimeTo
                : this.leaveDetails.newDateTo,
            description: this.leaveDetails.description,
            status: this.leaveDetails.status,
            state: this.leaveDetails.state,
            type: this.leaveDetails.type,
            fileId: this.leaveDetails.fileId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 201) {
      
            this.toast(res.data, "success");
            this.addLeaveItemDialog = false;
            this.leaveDetails = [];
            this.getLeaveList();
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.file = [];
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addLeaveItemDialog = false;
            this.leaveDetails = [];
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.file = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.addLeaveItemDialog = false;
          this.leaveDetails = [];
          this.myFile = new Object({
            uploadPercentage: 0,
          });
          this.file = [];
        });
    },
    myUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(this.baseUrl + "/clinic/hr/leave/upload", formData, {
          headers: {
            Authorization: "Bearer: " + localStorage.getItem("token"),
          },
          onUploadProgress: ((progressEvent) => {
            this.myFile.uploadPercentage =
              Math.round((progressEvent.loaded * 100) / progressEvent.total) -
              1;
          }).bind(this),
        })
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.leaveDetails.fileId = d.data;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editedUpload(file) {
      // NOTE (m-myUpload) to upload files
      this.myFile.showProgress = true;
      this.myFile.variant = "info";
      this.myFile.uploadPercentage = 0;
      this.myFile.isUploaded = false;
      this.myFile.uploadButDisable = true;
      var formData = new FormData();
      formData.append(file.name, file);

      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leave/upload?leaveId=" + this.selected.id,
          formData,
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          }
        )
        .then((d) => {
          if (d.status == 201) {
            this.myFile.isUploaded = true;
            this.myFile.uploadPercentage = 100;
            this.myFile.striped = false;
            this.myFile.variant = "success";
            this.myFile.uploadButDisable = false;
            this.selectedEdit.fileId = d.data;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    editLeaveItem() {
      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leave/edit",
          {
            leaveId: this.selected.id,
            startDate:
              this.selectedEdit.type == "hourly"
                ? this.selectedEdit.startDate + this.selectedEdit.startTime
                : this.selectedEdit.startDate + "00:00",

            endDate:
              this.selectedEdit.type == "hourly"
                ? this.selectedEdit.endDate + this.selectedEdit.endTime
                : this.selectedEdit.endDate + "23:59",
            description: this.selectedEdit.description,
            status: this.selectedEdit.status,
            state: this.selectedEdit.state,
            type: this.selectedEdit.type,
            fileId: this.selectedEdit.fileId,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.edited = false;
            this.leaveItemDialog = false;
            this.selectedEdit = [];
            this.editedDateFrom = "";
            this.editedDateTo = "";
            this.editedTimeFrom = "";
            this.editedTimeTo = "";
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.file = [];
            this.CurrentPage = 1;

            this.getLeaveList();
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.edited = false;
            this.leaveItemDialog = false;
            this.selectedEdit = [];
            this.editedDateFrom = "";
            this.editedDateTo = "";
            this.editedTimeFrom = "";
            this.editedTimeTo = "";
            this.myFile = new Object({
              uploadPercentage: 0,
            });
            this.file = [];
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.edited = false;
          this.leaveItemDialog = false;
          this.selectedEdit = [];
          this.editedDateFrom = "";
          this.editedDateTo = "";
          this.editedTimeFrom = "";
          this.editedTimeTo = "";
          this.myFile = new Object({
            uploadPercentage: 0,
          });
          this.file = [];
        });
    },
    onRowSelected(item) {
      this.selected = JSON.parse(JSON.stringify(item));
      this.edited = false;
      this.leaveItemDialog = true;

      this.$http
        .post(
          this.baseUrl + "/clinic/hr/leave/getInfo",
          {
            leaveId: this.selected.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.leaveInfo = JSON.parse(JSON.stringify(res.data));
            this.selectedEdit = JSON.parse(JSON.stringify(res.data));
            this.selectedEdit.type = this.types.filter(
              (x) => x.text == this.selectedEdit.type
            )[0].value;
            this.selectedEdit.state = this.states.filter(
              (x) => x.text == this.selectedEdit.state
            )[0].value;
            this.selectedEdit.status = this.status.filter(
              (x) => x.text == this.selectedEdit.status
            )[0].value;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.vLoading = true;
    this.getLeaveList();
  },
};
</script>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 82% !important;
}
</style>
